import React, {useState, useEffect} from 'react';
import FormInput from "../../components/UI/Input";
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

const From = () => {
    const { addToast } = useToasts()
const [inputs, setInputs] = useState({})

const [checkboxProductorAlimentos, setCheckboxProductorAlimentos] = useState(true)
const [checkboxDistribuidorAbarrotes, setCheckboxDistribuidorAbarrotes] = useState(true)
const [checkboxDuenoTienda, setCheckboxDuenoTienda] = useState(true)
const [checkBoxEmpresario, setCheckboxEmpresario] = useState(true)
const [checkBoxEmpresarioVoluntario, setCheckboxEmpresarioVoluntario] = useState(true)


    const onSubmit = (e) => {
        e.preventDefault()

        axios.post("/.netlify/functions/sendgrid-colabora", {
            inputs
        })
        .then((res) =>  addToast('Tu correo ha sido enviado exitosamente.', { appearance: 'success' }))
        .catch(e => {
            addToast('¡Oops! Hubo un error. Intenta nuevamente.', { appearance: 'error' })
            console.log(e)})
    }

    return (
        <div className="contact-form-wrap">
            <form id="contact-form" onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            onChange={(e) => {
                                e.persist();
                                setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
                                console.log(inputs)
                            }}
                            name={'name'}
                            placeholder={'Nombre Completo'}
                        />
                    </div>

                    
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'email'}
                            onChange={(e) => {
                                e.persist();
                                setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
                                console.log(inputs)
                            } }
                            name={'email_address'}
                            placeholder={'Correo Electrónico'}
                        />
                    </div>
                    <div className="col-md-4 flex" style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <label> Soy productor de alimentos
                    <FormInput
                    
                            tag={'checkbox'}
                            type={'checkbox'}
                            onChange={(event) => {
    if (!checkboxProductorAlimentos) {
        setCheckboxProductorAlimentos(true);
        setInputs({...inputs, [event.target.name]: checkboxProductorAlimentos})

    } else {
        setCheckboxProductorAlimentos(false);
        setInputs({...inputs, [event.target.name]: checkboxProductorAlimentos})
    }
    console.log(checkboxProductorAlimentos)
    console.log(inputs)
}}
                            name={'checkbox_productor_alimentos'}
                            
                        />
                        </label>
                    </div>

                    <div className="col-md-4" style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <label>Soy distribuidor de abarrotes
                    <FormInput
                            tag={'checkbox'}
                            type={'checkbox'}
                            onChange={(event) => {
    if (!checkboxDistribuidorAbarrotes) {
        setCheckboxDistribuidorAbarrotes(true);
        setInputs({...inputs, [event.target.name]: checkboxDistribuidorAbarrotes})

    } else {
        setCheckboxDistribuidorAbarrotes(false);
        setInputs({...inputs, [event.target.name]: checkboxDistribuidorAbarrotes})
    }
    console.log(checkboxProductorAlimentos)
    console.log(inputs)
}}
                            name={'checkbox_distribuidor_abarrotes'}
                        />
                        </label>
                    </div>

                    <div className="col-md-4" style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <label>Soy dueño de una tienda
                    <FormInput
                            tag={'checkbox'}
                            type={'checkbox'}
                            onChange={(event) => {
    if (!checkboxDuenoTienda) {
        setCheckboxDuenoTienda(true);
        setInputs({...inputs, [event.target.name]: checkboxDuenoTienda})

    } else {
        setCheckboxDuenoTienda(false);
        setInputs({...inputs, [event.target.name]: checkboxDuenoTienda})
    }
    console.log(checkboxProductorAlimentos)
    console.log(inputs)
}}
                            name={'checkbox_dueno_tienda'}
                        />
                        </label>
                    </div>

                    <div className="col-md-6" style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <label>Soy empresario/profesionista
                    <FormInput
                            tag={'checkbox'}
                            type={'checkbox'}
                            onChange={(event) => {
    if (!checkBoxEmpresario) {
        setCheckboxEmpresario(true);
        setInputs({...inputs, [event.target.name]: checkBoxEmpresario})

    } else {
        setCheckboxEmpresario(false);
        setInputs({...inputs, [event.target.name]: checkboxDuenoTienda})
    }
    console.log(checkBoxEmpresario)
    console.log(inputs)
}}
                            name={'checkbox_empresario'}
                        />
                        </label>
                    </div>

                    <div className="col-md-6" style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <label style={{textAlign:'center'}}>Soy empresario y quiero que mi equipo sea voluntario
                    <FormInput
                            tag={'checkbox'}
                            type={'checkbox'}
                            onChange={(event) => {
    if (!checkBoxEmpresarioVoluntario) {
        setCheckboxEmpresarioVoluntario(true);
        setInputs({...inputs, [event.target.name]: checkBoxEmpresarioVoluntario})

    } else {
        setCheckboxEmpresarioVoluntario(false);
        setInputs({...inputs, [event.target.name]: checkBoxEmpresarioVoluntario})
    }
    console.log(checkBoxEmpresarioVoluntario)
    console.log(inputs)
}}
                            name={'checkbox_empresario_voluntarios'}
                        />
                        </label>
                    </div>



                    <div className="col-12">
                        <FormInput
                            tag={'textarea'}

                            onChange = {e =>  {
                                e.persist();
                                setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
                                console.log(inputs)
                            } }
                            name={'message'}
                            placeholder={'Mensaje'}
                        />

                        <FormInput
                            tag={'button'}
                            classes={'btn-outline'}
                            style={{color:'#288d36'}}
                          
                        />

                        <div className="form-message"/>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default From;