import React from 'react';
import SectionTitle from "../UI/SectionTitle";
import BlogItem from "./blogItem";
import Blogs from '../../data/Blog2/blog';
import {Link} from 'gatsby'

import UilMoney from '@iconscout/react-unicons/icons/uil-dollar-alt'
import UilUtensils from '@iconscout/react-unicons/icons/uil-utensils'
import UilCar from '@iconscout/react-unicons/icons/uil-car'
import UilUsers from '@iconscout/react-unicons/icons/uil-users-alt'

function Blog() {
    return (
        <div className="blog-area-wrapper sm-top">
            <div className="container">
                <div className="row">
                    <div className="col-12 text-center">
                    <SectionTitle title="" heading="En Banco de Alimentos Mérida realizamos una labor extraordinaria gracias la
generosidad y solidaridad de personas, empresas, productores y organizaciones que
con sus donaciones en especie o fondos económicos hacen posible marcar la
diferencia en la vida de miles de yucatecos."/>
                    <br/>
                        <SectionTitle title="" heading="Todos podemos ayudar"/>
                    </div>
                </div>

                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-evenly', alignItems:'center', flexWrap: 'wrap', marginBottom:'90px'}}>
                  
                    <div className={'col-md-6 col-lg-3'}>
            <div className="blog-item" style={{backgroundColor:'#ffc966', height:'370px'}}>

                <div className="blog-content" >
                    <h2 className="h5" style={{textAlign:'center'}}>Realiza tu donativo a través de transferncia bancaria o PayPal</h2>
                    <br/>
                    <div style={{paddingBottom:'10%',display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'space-evenly'}}>

                    <a style={{marginBottom:'5%'}} target={'_blank'} rel={'noopener noreferrer'} href='https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=YXBYS99ELREUW&source=url'><div style={{backgroundColor:'#d33', transition:'0.2s',transitionDuration:'0.2s', transitionProperty:'all', transitionTimingFunction:'ease', transitionDelay:'0s'}} className="btn btn-brand">Paypal</div></a>


                            <Link to={`/transferencia`} style={{backgroundColor:'#d33', transition:'0.2s',transitionDuration:'0.2s', transitionProperty:'all', transitionTimingFunction:'ease', transitionDelay:'0s'}} className="btn btn-brand">Transferencia</Link>
                    </div>

                </div>
            </div>
        <div className="blob-circle1" style={{backgroundColor:'black', width:'70px', height:'70px',  borderRadius:'50%', margin:'0 auto', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <UilMoney color="white" size="40px"/>
        </div>
            <p style={{textAlign:'center'}}>Efectivo</p>
        </div>

        <div className={'col-md-6 col-lg-3'}  >
            <div className="blog-item" style={{backgroundColor:'#ffc966' , height:'370px'}}>

                <div className="blog-content">
                    <h2 className="h5" style={{textAlign:'center'}}>¿Eres productor de alimentos o distribuidor de abarrotes o dueño de una tienda?</h2>
                   
                </div>
            </div>
            <div className="blob-circle1" style={{backgroundColor:'black', width:'70px', height:'70px',  borderRadius:'50%', margin:'0 auto', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <UilUtensils color="white" size="40px"/>
            </div>
            <p style={{textAlign:'center'}}>Alimentos</p>
        </div>
        <div className={'col-md-6 col-lg-3'} >
            <div className="blog-item" style={{backgroundColor:'#ffc966' , height:'370px'}}>

                <div className="blog-content">
                    <h2 className="h5" style={{textAlign:'center'}}>¿Eres profesionista o tienes una empresa y alguno de tus bienes o servicios
nos pueden ayudar en nuestras tareas?</h2>
                   

                </div>
            </div>
            <div className="blob-circle1" style={{backgroundColor:'black', width:'70px', height:'70px',  borderRadius:'50%', margin:'0 auto', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <UilCar color="white" size="40px"/>
            </div>
            <p style={{textAlign:'center'}}>Bienes y servicios</p>
        </div>
        <div className={'col-md-6 col-lg-3'}>
            <div className="blog-item" style={{backgroundColor:'#ffc966' , height:'370px'}}>

                <div className="blog-content" >
                    <h2 className="h5" style={{textAlign:'center'}}>Trabajas en una empresa y quieres que tu equipo sea voluntario?</h2>
        
                </div>
            </div>
            <div className="blob-circle1" style={{backgroundColor:'black', width:'70px', height:'70px',  borderRadius:'50%', margin:'-0% auto', zIndex:'5550', display:'flex', justifyContent:'center', alignItems:'center'}}>
                <UilUsers size="40px" color="white"/>
            </div>
            <p style={{textAlign:'center'}}>Voluntariados</p>
        </div>
                </div>

                
            </div>
        </div>
    );
}

export default Blog;