import React from 'react';
import From from "./From";
import ContactInfo from "./ContactInfo";


const ContactPage = () => {
    return (
        <div className={'contact-page-area-wrapper sp-y'}>
            <div className="container">
                <div className="contact-content-wrap">
                    <div className="row" style={{display:'flex',flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
                        <div className="col-lg-8">
                            <div className="contact-form-area contact-method" >
                                <h3 style={{textAlign:'center'}}>Colabora con nosotros</h3>
                                <From/>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;