import React, {Fragment} from 'react';
import PageHeader from '../components/PageHeader/index'
import Header from '../components/Header'
import Slider from '../components/Slider/home-two'
import About from '../components/About/home-two'
import SectionTitle from '../components/UI/SectionTitle/index'
import Services from '../components/Services'
import Features from '../components/Features'
import Testimonial from "../components/Testimonials/home-two";
import Team from "../components/Team/home-two";
import ContactPage from '../templates/Contact-colabora'
import Blog2 from "../components/Blog2";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import {ToastProvider} from 'react-toast-notifications'


const Colabora = () => {
    return (
        <ToastProvider placement="bottom-left" autoDismiss='true'>
        <Fragment >
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/colabora.png')}
                title="COLABORA"
                content=""
            />
            <Blog2 style={{marginBottom:'90px'}}/>
            <SectionTitle title="" heading="Beneficios de donar" />
            <Features  />
            <ContactPage/>
            <br/><br/><br/><br/><br/>
            <SectionTitle title="" heading="Nuestros aliados"/>
            <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginBottom:'60px'}}>
            <img  src={require('../assets/img/wall.png')} alt="ea"/>
            </div>

            <CallToAction/>
            <Footer/>
            <LoginRegister/>
            <MobileMenu/>
        </Fragment>
        </ToastProvider>
    );
};

export default Colabora;